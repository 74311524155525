import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const types = {
  SET_MOBILE: 'SET_MOBILE'
}

export default new Vuex.Store({
  state: {
    isMobile: false
  },
  mutations: {
    [types.SET_MOBILE](state, payload) {
      state.isMobile = payload;
    }
  },
  actions: {
    setMobile({ commit }, payload) {
      commit(types.SET_MOBILE, payload);
    }
  },
  getters: {
    isMobile: state => state.isMobile
  },
  modules: {
  }
})
