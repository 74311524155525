<template>
    <div :class="[fixed? 'fixed-menu' : 'menu']">
        <img src="@img/logo.png" width="210" alt="logo" @click="logoClick">
        <!-- pc -->
        <div class="menu-list" v-show="!isMobile">
            <ul>
                <li 
                    v-for="item in menuList"
                    :key="item.label">
                    <a 
                        v-if="!item.child"
                        :href="item.href"
                        @click="routerPage">{{item.label}}</a>
                    <Dropdown v-else @on-click="handleSubMenuClick">
                        <a :href="item.href">{{item.label}}</a>
                        <DropdownMenu slot="list">
                            <DropdownItem
                                v-for="subItem in item.child"
                                :name="subItem.link"
                                :key="subItem.label">{{subItem.label}}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </li>
            </ul>
        </div>

        <!-- mobile -->
        <div class="icon-menu" v-show="isMobile">
            <Icon type="ios-menu" @click="toggleMenu"/>
            <div class="dropdown" :style="{ 'height': `${menuHeight}px` }">
                <ul>
                    <li
                        v-for="item in menuList"
                        :key="item.label">
                        <a 
                            v-if="!item.child"
                            :href="item.href"
                            @click="$router.push({ name: 'Home' })">{{item.label}}</a>
                        <div v-else>
                            <a 
                                :href="item.href"
                                @click="toggleMenu">{{item.label}}</a><Icon type="ios-add" @click="toggle" />
                            <ul class="sub-menu" :style="{ 'height': `${subMenuHeight}px` }">
                                <li
                                    v-for="subItem in item.child"
                                    :key="subItem.label"
                                    @click="handleSubMenuClick(subItem.link)">{{subItem.label}}</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    
</template>
    
<script>
export default {
    name: 'MenuView',
    data() {
        return {
            menuHeight: 0,
            subMenuHeight: 0
        }
    },
    props: {
        fixed: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        isMobile() {
            this.menuHeight = 0;
        }
    },
    computed: {
        menuList() {
            return [
                // { label: 'ＨＯＭＥ', href: '#main' },
                { label: 'ＣＯＬＬＥＣＴＩＯＮ', href: '#collection', child: [
                    { label: 'ＰＡＰＥＲ', link: '/Paper' },
                    { label: 'ＭＵＴＡＴＩＯＮ', link: '/Mutation' },
                    { label: 'ＳＵＳＴＡＩＮＡＢＬＥ', link: '/Sustainable' },
                    { label: 'ＹＯＵＮＧ ＬＡＤＹ', link: '/YoungLady' },
                    { label: 'ＦＡＩＲＹ ＴＡＬＥＳ', link: '/Fantasy' },
                    { label: 'ＷＯＭＥＮＳＷＥＡＲ', link: '/Women' },
                    { label: 'ＩＬＬＵＳＴＲＡＴＩＯＮ', link: '/Illustration' },
                    { label: 'ＴＥＸＴＵＲＥＳ / ＴＥＸＴＩＬＥＳ', link: '/Textures' }
                ] },
                { label: 'ＳＴＯＲＹ', href: '#story' },
                { label: 'ＣＯＮＴＡＣＴ', href: '#contact' }
            ]
        }
    },
    methods: {
        toggleMenu() {
            const menuHeight = 144;
            this.menuHeight = this.menuHeight ? 0 : menuHeight;
            if (this.menuHeight) {
                this.subMenuHeight = 0;
            }
        },
        toggle() {
            const subMenuHeight = 288;
            this.subMenuHeight = this.subMenuHeight ? 0 : subMenuHeight;
            this.menuHeight = this.subMenuHeight ? this.menuHeight + subMenuHeight : this.menuHeight - subMenuHeight;
        },
        routerPage() {
            const isCurrentPath = this.$route.path === '/';
            
            if (!isCurrentPath) {
                this.$router.push({ name: 'Home' });
            }
        },
        handleSubMenuClick(name) {
            const isCurrentPath = this.$route.path === name;
            
            if (!isCurrentPath && name) {
                this.$router.push({ path: name });
                this.toggleMenu();
            }
        },
        logoClick() {
            this.$router.push({ name: 'Home' });
        }
    }
}
</script>

<style lang="scss">
.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;

    img { margin-bottom: 2rem; }

    .menu-list > ul > li{
        float: left;
        margin-right: 4rem;
        // font-family: msReferenceSansSerif;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        a { 
            color: #333333;
            font-size: 12px;
            &:hover {
                color: #8e8e8e;
            }
        }
    }

    .ivu-dropdown-menu {
        text-align: center;
        li {
            font-size: 10px !important;
        }
    }
}

.fixed-menu {
    position: fixed;
    top: 0;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5%;
    background: rgba(212,205,195,0.41);
    z-index: 1;

    img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .menu-list > ul > li{
        float: left;
        margin-right: 2rem;
        // font-family: msReferenceSansSerif;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        a { 
            color: #333333;
            font-size: 12px;
            &:hover {
                color: #8e8e8e;
            }
        }
    }

    .ivu-dropdown-menu {
        text-align: center;
        li {
            font-size: 10px !important;
        }
    }
}

.icon-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    .dropdown {
        position: absolute;
        top: 140px;
        margin: 5px 0;
        // font-family: msReferenceSansSerif;
        background: white;
        box-shadow: 0 1px 6px rgba(0,0,0,.2);
        border-radius: 4px;
        z-index: 9;
        overflow: auto;
        transition: ease .5s;

        > ul > li {
            color: #333333;
            font-size: 12px;
            line-height: 3;
            
            &:hover {
                color: #8e8e8e;
            }

            a { 
                color: #333333;
                font-size: 12px;
                &:hover {
                    color: #8e8e8e;
                }
            }
        }

        .sub-menu {
            background: $collection-background;
            padding: 0 10px;
            overflow: auto;
            transition: ease .5s;
            color: #333333;
            > li:hover {
                color: #8e8e8e;
            }
        }
    }
}
</style>
