import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@V/'),
  },
  {
    path: '/Illustration',
    name: 'Illustration',
    component: () => import(/* webpackChunkName: "Illustration" */ '@V/Illustration'),
  },
  {
    path: '/Fantasy',
    name: 'Fantasy',
    component: () => import(/* webpackChunkName: "Fantasy" */ '@V/Fantasy'),
  },
  {
    path: '/YoungLady',
    name: 'YoungLady',
    component: () => import(/* webpackChunkName: "YoungLady" */ '@V/YoungLady'),
  },
  {
    path: '/Textures',
    name: 'Textures',
    component: () => import(/* webpackChunkName: "Textures" */ '@V/Textures'),
  },
  {
    path: '/Sustainable',
    name: 'Sustainable',
    component: () => import(/* webpackChunkName: "Sustainable" */ '@V/Sustainable'),
  },
  {
    path: '/Mutation',
    name: 'Mutation',
    component: () => import(/* webpackChunkName: "Mutation" */ '@V/Mutation'),
  },
  {
    path: '/Paper',
    name: 'Paper',
    component: () => import(/* webpackChunkName: "Paper" */ '@V/Paper'),
  },
  {
    path: '/Women',
    name: 'Women',
    component: () => import(/* webpackChunkName: "Women" */ '@V/Women'),
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
