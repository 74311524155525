import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app';
import 'view-design/dist/styles/iview.css';
import { 
  Row, Col, Dropdown, DropdownMenu, DropdownItem, Icon, Menu, MenuItem, Submenu
} from 'view-design';

Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Icon', Icon);
Vue.component('Menu', Menu);
Vue.component('MenuItem', MenuItem);
Vue.component('Submenu', Submenu);

Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: "AIzaSyD0F3wDL7rqXkA7_PEdSNHnszISzPrJWgY",
  authDomain: "wen-ching-yen.firebaseapp.com",
  projectId: "wen-ching-yen",
  storageBucket: "wen-ching-yen.appspot.com",
  messagingSenderId: "208845011022",
  appId: "1:208845011022:web:c9062ea5a8f1dfc43b6b1f",
  measurementId: "G-1WM2ZGFPMT"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
