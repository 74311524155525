<template>
    <div id="app">
        <!-- cover -->
        <div id="cover"></div>
        <!-- menu -->
        <menu-view :isMobile="isMobile"/>
        <!-- fixed menu -->
        <menu-view v-show="isScrollToPoint && !isMobile" :fixed="true" />
        <router-view/>
    </div>
</template>

<script>
import MenuView from '@C/MenuView';
import { mapActions } from 'vuex';

export default {
    components: {
        MenuView
    },
    data() {
        return {
            isScrollToPoint: false,
            isMobile: false
        }
    },
    mounted() {
        const mobileWidth = 768;

        this.isMobile =  window.innerWidth < mobileWidth;
        this.setMobile(this.isMobile);

        window.addEventListener('resize', (e) => {
            this.isMobile = e.target.innerWidth < mobileWidth;
            this.setMobile(this.isMobile);
        });

        window.addEventListener('scroll', () => {
            let scrollTop = document.documentElement.scrollTop;
            this.isScrollToPoint = scrollTop >= 125;
            
        }, true);

        const allRouters = this.$router.options.routes;
        const currentPath = this.$route.path;
        const isMatch = allRouters.some(route => route.path === currentPath);
        if (!isMatch) {
            this.$router.replace({ path: '/' });
        }
    },
    methods: {
        ...mapActions([
            'setMobile'
        ])
    }
}
</script>

<style lang="scss">
@font-face {
  font-family: msReferenceSansSerif;
  src: url('./assets/font/ms reference sans serif.ttf');
  font-weight: bold;
}

@font-face {
  font-family: ArialBlack;
  src: url('./assets/font/Arial-Black.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Maturasc;
  src: url('./assets/font/MATURASC_1.TTF');
//   font-weight: bold;
}

#cover {
    width: 100%;
    height: 100vh + 123;
    position: absolute;
    z-index: -100;
    // animation: fadeIn 5s ease-in-out;
}

@keyframes fadeIn {
    0% { opacity: 0 }
    100% { opacity: 1 }
}
</style>
